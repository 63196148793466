<template>
  <el-main>
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <BreadCrumb />
      </el-col>
      <el-col :span="12" class="btn-group">
        <el-button type="primary" plain @click="add"><i class="el-icon-plus"></i> Add</el-button>
        <el-button @click="getList"><i class="el-icon-refresh-right el-icon--right"></i> Refresh</el-button>
      </el-col>
    </el-row>
    <el-table stripe @sort-change="handleColumn" style="margin-top: 10px" border v-loading="loading" :data="list" :sort="sort">
      <el-table-column width="120" prop="version" sortable="custom" label="Version">
      </el-table-column>
      <el-table-column width="150" prop="releasedAt" label="Released Date">
        <template slot-scope="scope">
          {{ getDate(scope.row.releasedAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description">
      </el-table-column>
      <el-table-column width="350" prop="md5" label="Checksum">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="Actions"
        width="250">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="edit(scope.$index, scope.row)"><i class="el-icon-folder-opened"></i></el-button>
          <el-button
            type="text"
            @click="deleteRet(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, total"
      :total="total">
    </el-pagination>
  </el-main>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  components: { BreadCrumb },
  data () {
    const query = this.$route.query
    return {
      loading: false,
      list: [],
      total: 0,
      sort: {
        prop: query.sort || 'createdAt',
        order: query.order || 'descending'
      },
      search: query.searchStr ? Number(query.searchStr) : '',
      pageSize: query.pageSize ? Number(query.pageSize) : 10,
      currentPage: query.currentPage ? Number(query.currentPage) : 1,
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: async function () {
    this.getList()
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD')
    },
    edit (index, row) {
      this.$router.push({
        path: `/work/model/${row._id}`
      })
    },
    add (index, row) {
      this.$router.push({
        path: `/work/model/new`
      })
    },
    deleteRet (index, row) {
      this.$confirm('This operation will be permanently deleted, do you want to continue?', 'Warn', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(async() => {
          try {
            await this.$store.httpDispatch({})('deleteModel', {
              _id: row._id
            })
          } catch (err) {
            console.log(err)
            this.$notify({
              title: 'Request failed',
              type: 'error'
            })
            return false
          }
          this.getList()
          this.$message({
            type: 'success',
            message: 'successfully deleted!'
          });
        }).catch(() => {
        })
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleColumn (column) {
      this.sort = column
      this.getList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getList()
    },
    async getList () {
      this.loading = true
      let result = {}
      let query = {
        pageSize: this.pageSize,
        currentPage: this.currentPage,
        searchStr: this.search,
        sort: this.sort.prop,
        order: this.sort.order
      }
      // this.$router.push({
      //   path: '/work/model',
      //   query
      // })
      try {
        result = await this.$store.httpDispatch({})('getModelList', query)
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        this.list = data.models
        this.total = data.total
      }
      this.loading = false
    }
  }
}
</script>
